import { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  getIsFetchingPrecisionParcels,
  getTotalCount,
  getIsInitPrecisionPage,
} from "../../../shared/api/sentinel/precision/precision.selectors";
import { getPrecisionParcelListTextFilter } from "../selectors/precisionParcelList.selectors";

import { setTaskdataIsVisible } from "../actions/precision.actions";

import { getPrecisionParcelsApi } from "../../../shared/api/sentinel/precision/precision.api";
import { getTaskdataIntegrationStatusApi } from "../../../shared/api/sentinel/taskdata/taskdata.api";
import useToggles from "../../../shared/toggles/toggles";
import TOGGLES from "../../../shared/toggles/toggles.const";
import { AsyncFn } from "../../../types";

import { PrecisionState } from "../../../reducers/precision.reducer.types";

type Props = {
  isPageInitCall: boolean;
  selectedTabIndex?: number;
};

// TODO: this should be checked in PrecisionWrapper and assembly routes according to that
const useIsPrecisionFarmingActive = ({
  isPageInitCall,
  selectedTabIndex,
}: Props): boolean | undefined => {
  const [, , isToggleActive] = useToggles();
  const activeServicesTabIsSelected = (() => {
    if (isToggleActive(TOGGLES.SEASON_DASHBOARD_TOGGLE.name)) {
      return selectedTabIndex !== 2;
    } else {
      return selectedTabIndex !== 1;
    }
  })();

  const dispatch = useDispatch();
  const parcelCount = useSelector(getTotalCount);
  const textFilter = useSelector(getPrecisionParcelListTextFilter);
  const settled = useSelector(
    (state: PrecisionState) => !getIsFetchingPrecisionParcels(state),
  );
  const viewIsInit = useSelector(getIsInitPrecisionPage);

  useEffect(() => {
    if (
      activeServicesTabIsSelected &&
      ((isPageInitCall && !viewIsInit) || (!isPageInitCall && viewIsInit))
    ) {
      dispatch(getPrecisionParcelsApi());
      dispatch((getTaskdataIntegrationStatusApi as unknown as AsyncFn)()).then(
        (res) => {
          dispatch(
            setTaskdataIsVisible(!(res.error && res.payload.status === 403)),
          );
        },
      );
    }
  }, [
    activeServicesTabIsSelected,
    dispatch,
    isPageInitCall,
    selectedTabIndex,
    viewIsInit,
  ]);

  if (settled) {
    return parcelCount > 0 || (parcelCount === 0 && textFilter.length > 0);
  }
};

export { useIsPrecisionFarmingActive };
