import { z } from "zod";
import { useSearchParams } from "../../../hooks/useSearchParams";
import {
  CROPS_KEY,
  ORDER_BY_KEY,
  ORDER_KEY,
  PAGE_KEY,
  ROWS_PER_PAGE_KEY,
  TEXT_FILTER_KEY,
} from "./precisionV2.constants";
import { TableSortLabelProps } from "@mui/material";
import { AdvancedFilterType } from "../../../types";

function fromEntries<T = any>(
  searchParams: URLSearchParams,
): Record<string, T> {
  return Array.from(searchParams.entries()).reduce(
    (acc, [key, value]) => {
      acc[key] = value as T;
      return acc;
    },
    {} as Record<string, T>,
  );
}

const PAGE_DEFAULT = 0;
const ROW_PER_PAGE_DEFAULT = 100;

const paramsSchema = z.object({
  page: z.coerce.number().default(PAGE_DEFAULT),
  rowsPerPage: z
    .preprocess(
      (val) => parseInt(val as string, 10),
      z.union([
        z.literal(10),
        z.literal(25),
        z.literal(50),
        z.literal(ROW_PER_PAGE_DEFAULT),
      ]),
    )
    .default(ROW_PER_PAGE_DEFAULT),
  textFilter: z.string().optional(),
  order: z.string().default("asc"),
  orderBy: z.string().default("name"),
  advancedFilter: z
    .object({
      crops: z.array(z.string()).default([]),
    })
    .optional()
    .default({ crops: [] }),
});

const usePageParams = () => {
  const [searchParams] = useSearchParams();
  const rawParams = fromEntries(searchParams);

  rawParams.advancedFilter = {
    crops: rawParams?.crops ? rawParams?.crops?.split(",") : [],
  };

  const safeParamsResult = paramsSchema.safeParse(rawParams);

  if (!safeParamsResult.success) {
    console.error("Invalid search params:", safeParamsResult.error);
    return {
      page: PAGE_DEFAULT,
      setPage: () => {},
      rowsPerPage: ROW_PER_PAGE_DEFAULT,
      setRowsPerPage: () => {},
      textFilter: "",
      setTextFilter: () => {},
      order: "asc" as const,
      setOrder: () => {},
      orderBy: "name",
      setOrderBy: () => {},
      setOnSort: () => {},
      advancedFilter: { crops: [] },
      setAdvancedFilter: () => {},
    };
  }

  const safeParams = safeParamsResult.data;
  const [, setSearchParams] = useSearchParams();

  const setPage = (value: number) => {
    setSearchParams((c) => {
      c.set(PAGE_KEY, value.toString());
      return c;
    });
  };

  const setRowsPerPage = (value: number) => {
    setSearchParams((c) => {
      c.set(ROWS_PER_PAGE_KEY, value.toString());
      return c;
    });
    setPage(PAGE_DEFAULT);
  };

  const setTextFilter = (value: string) => {
    setSearchParams((c) => {
      c.set(TEXT_FILTER_KEY, value.toString());
      return c;
    });
    setPage(PAGE_DEFAULT);
  };

  const setOrder = (value: TableSortLabelProps["direction"]) => {
    setSearchParams((c) => {
      c.set(ORDER_KEY, value || "asc");
      return c;
    });
  };

  const setOrderBy = (value: string) => {
    setSearchParams((c) => {
      c.set(ORDER_BY_KEY, value.toString());
      return c;
    });
  };

  const setOnSort = (
    newOrder: TableSortLabelProps["direction"],
    newOrderBy: string,
  ) => {
    setSearchParams((c) => {
      setOrder(newOrder);
      setOrderBy(newOrderBy);
      return c;
    });
  };

  const setAdvancedFilter = (newFilter: AdvancedFilterType) => {
    setSearchParams((c) => {
      if (newFilter.crops?.length) {
        const cropIds = newFilter.crops.map((crop) => crop.id).join(",");
        c.set(CROPS_KEY, cropIds);
      } else {
        c.delete(CROPS_KEY);
      }
      setPage(PAGE_DEFAULT);
      return c;
    });

    safeParams.advancedFilter = {
      crops: newFilter.crops ? newFilter.crops.map((crop) => crop.id) : [],
    };
  };

  return {
    page: safeParams.page,
    setPage,
    rowsPerPage: safeParams.rowsPerPage,
    setRowsPerPage,
    textFilter: safeParams.textFilter,
    setTextFilter,
    order: safeParams.order as TableSortLabelProps["direction"],
    orderBy: safeParams.orderBy,
    setOnSort,
    advancedFilter: safeParams.advancedFilter,
    setAdvancedFilter,
  };
};

export { usePageParams };
