import { useEffect } from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";
import { SelectObject } from "../../../../shared/components/form/FormSelect/types";
import { SeasonTo } from "../../../../generated/api/satellite";
import { PRECISION_URLS } from "../../../../core/precision/precision.constants";

type Props = {
  isInit: boolean;
  seasonsOptions: SelectObject<SeasonTo>[];
  farmId: string;
};

const useSeason = ({ isInit, seasonsOptions, farmId }: Props) => {
  const history = useHistory();

  const { seasonId: seasonIdQueryParam } = useParams<{ seasonId?: string }>();

  const season = seasonsOptions.find((s) => s.key === seasonIdQueryParam);

  useEffect(() => {
    if (isInit) {
      if (!season && seasonsOptions.length > 0) {
        history.replace(
          generatePath(`/farm/${farmId}/${PRECISION_URLS.seasonDetail()}`, {
            seasonId: seasonsOptions[0].key,
          }),
        );
      }
    }
  }, [season, seasonsOptions, isInit, history, farmId]);

  const handleSeasonChange = (obj: SelectObject<SeasonTo>) => {
    history.push(
      generatePath(`/farm/${farmId}/${PRECISION_URLS.seasonDetail()}`, {
        seasonId: obj.key,
      }),
    );
  };

  return {
    season,
    handleSeasonChange,
  };
};

export { useSeason };
