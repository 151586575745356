import { useEffect, useState } from "react";
import { usePageParams } from "./usePageParams";
import { InternalCropTo } from "../../../../generated/api/satellite";

type Props = {
  crops: InternalCropTo[];
};

const useCustomizedUrlParams = ({ crops }: Props) => {
  const {
    setTextFilter: zodSetTextFilter,
    textFilter: zodTextFilter,
    advancedFilter: zodAdvancedFilter,
  } = usePageParams();

  const [init, setInit] = useState(false);
  // textFilter state because of debouncing
  const [textFilter, setTextFilter] = useState(zodTextFilter ?? "");

  useEffect(() => {
    if (init) {
      zodSetTextFilter(textFilter ?? "");
    }
  }, [textFilter]);

  useEffect(() => {
    setInit(true);
  }, []);

  const matchedCrops = zodAdvancedFilter.crops
    .map((id) => crops?.find((crop) => crop.id === id))
    .filter((crop): crop is InternalCropTo => crop !== undefined);
  const advancedFilter = { crops: matchedCrops };

  return {
    textFilter,
    setTextFilter,
    advancedFilter,
  };
};

export { useCustomizedUrlParams };
